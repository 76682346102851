function useTranslations() {
  const language = navigator.language.substring(0, 2);

  const translations = {
    "it": {
      "menu": {
        "Portale": "Portale",
        "Lista Chiamate": "Lista Chiamate",
        "Gestione Credito": "Gestione Credito",
        "Software": "Software",
        "Impostazioni SIP": "Impostazioni SIP"
      },
      "login": {
        "enter_username": "Inserire Username",
        "enter_password": "Inserire Password",
        "username_required": "Richiesto Username",
        "password_required": "Richiesta Password",
        "registrazione_utente": "Registrazione Nuovo Utente",
        "recupero_password": "Reset Password",
        "login_fallita": "Login Fallita.",
        "user_inactive": "Utente non attivo. E' necessario attivarlo cliccando sul link inviato via email.",
        "max_failed_login_reached": "Numero massimo di tentativi raggiunto. E' necessario eseguire il reset della password."
      },
      "activate": {
        "activation_ok": "Account attivato con successo!<br/>Ciao <b>[USERNAME]</b>, il tuo account è stato attivato con successo. Ora puoi accedere e iniziare a utilizzare i nostri servizi.<br/><br/>Prossimo passo: verrai trasferito automaticamente alla pagina di login tra pochi secondi.<br/>Se il trasferimento non avviene, clicca sul pulsante qui sotto:<br/><br/><a href='/login'>VAI AL LOGIN</a><br/><br/>Grazie per aver scelto CallWebCom!",
        "activation_ko": "Errore nella fase di attivazione o utente già attivo."
      },
      "register": {
        "registrazione_account": "Registrazione Account",
        "enter_username": "Inserire Username",
        "enter_password": "Inserire Password",
        "enter_email": "Inserire Email",
        "username_required": "Richiesto Username",
        "password_required": "Richiesta Password",
        "email_required": "Richiesta Email",
        "email_invalid": "Email Non Valida",
        "crea_account": "Crea Account",
        "login": "Hai già un account?",
        "register_failed": "Registrazione Fallita.",
        "email_exists": "Email già utilizzata",
        "username_exists": "Username già esistente.",
        "password_do_not_match": "Le password non coincidono.",
        "registrazione_fallita": "Registrazione fallita. Riprovare con altro Username o con una Password più complessa.",
        "registrazione_ok": "Registrazione andata a buon fine! Entro pochi secondi sarai reindirizzato alla pagina di login.",
        "password_format": "La password deve essere di almeno 8 caratteri e deve contenere almeno una maiuscola, almeno una minuscola e almeno un numero."
      },
      "forgot_password": {
        "recupero_password": "Reset Password",
        "login": "Login",
        "recupera_password": "Reset Password",
        "recupero_fallito": "Recupero non andato a buon fine.",
        "recupero_ok": "Abbiamo inviato una email con la nuova password al tuo indirizzo email.",
        "inserire_username": "Inserire Username",
        "inserire_email": "Inserire Email",
        "username_richiesto": "Richiesto Username",
        "email_required": "Richiesta Email",
        "email_invalid": "Email Non Valida",
      },
      "home": {
        "welcome!": "Benvenuto!",
        "remaining_credit": "Il tuo credito residuo è:",
        "credit_recharge": "Ricarica Credito",
        "credit_recharge_description": "Nel caso in cui il tuo credito stia terminando, o sia terminato, accedi alla sezione per ricaricarlo.",
        "show_calls": "Consulta Chiamate",
        "show_calls_description": "Accedi alla lista delle chiamate ricevute e fatte, da tutti i numeri associati al tuo account.",
        "transactions": "Transazioni",
        "transactions_description": "Verifica tutte le transazioni eseguite. Puoi inoltre abilitare la ricarica mensile automatica."
      },
      "calls": {
        "lista_chiamate": "Lista Chiamate",
        "CallId": "Chiamante",
        "Charge": "Costo",
        "Destination": "Destinatario",
        "Duration": "Durata",
        "StartTime": "Inizio"
      },
      "credit": {
        "credito_attuale": "Il tuo credito attuale è",
        "opzioni_lato": "Utilizza una delle opzioni a lato, per incrementare il tuo credito.",
        "ricarica_singola": "Ricarica Singola",
        "seleziona_importo": "Seleziona Importo:",
        "importo": "Importo",
        "ricarica": "Ricarica",
        "ricarica_ricorrente": "Attiva Ricarica",
        "ricarica_ricorrente_mensile": "Ricarica Ricorrente Mensile",
        "nessuna_ricarica_ricorrente": "Nessuna ricarica ricorrente attiva.",
        "sottoscrizione_attiva": "Attualmente hai una sottoscrizione ricorrente attiva di",
        "creata_il": "creata il",
        "annulla_ricarica_ricorrente": "Annulla Ricarica Ricorrente",
        "lista_pagamenti": "Lista Pagamenti",
        "data_creazione": "Data Creazione",
        "id_transazione": "ID Transazione",
        "descrizione": "Descrizione",
        "importo_euro": "Importo (€)",
        "stato": "Stato",
        "ricarica_eseguita": "Ricarica eseguita correttamente.",
        "ricarica_in_errore": "Errore in fase di ricarica."
      },
      "software": {
        "pc_client_descrizione": "Salva l'installer sul tuo computer! Dopo il download, doppio clic sul file di installazione.",
        "android_client_descrizione": "Basta fare clic sul box e installare l'app YourDialer sul tuo telefono cellulare Android!",
        "ios_descrizione": "Basta fare clic sul box e installare l'app YourDialer sul tuo telefono/iPad/iPod Apple!."
      },
      "sip": {
        "text": "Se riscontri problemi con la registrazione del tuo dispositivo SIP, puoi anche sostituire il carattere @ con il carattere *. Cioè. se il tuo nome utente è utente@provider puoi anche utilizzare utente*provider nelle impostazioni SIP. Ciò potrebbe risolvere i problemi di registrazione per alcuni dispositivi SIP e softphone SIP.",
      }
    },
    "en": {
      "menu": {
        "Portale": "Portal",
        "Lista Chiamate": "Call List",
        "Gestione Credito": "Credit Management",
        "Software": "Software",
        "Impostazioni SIP": "SIP Settings"
      },
      "login": {
        "enter_username": "Enter Username",
        "enter_password": "Enter Password",
        "username_required": "Username Required",
        "password_required": "Password Required",
        "registrazione_utente": "Register New User",
        "recupero_password": "Forgot Password",
        "login_fallita": "Login Failed.",
        "user_inactive": "User not active. It needs to be activated by clicking on the link sent via email.",
        "max_failed_login_reached": "Max Failed Login Reached. You need to reset password."
      },
      "activate": {
        "activation_ok": "Account successfully activated!<br/>Hello <b>[USERNAME]</b>, your account has been successfully activated. You can now log in and start using our services.<br/><br/>Next step: you will be automatically redirected to the login page in a few seconds.<br/>If the redirection does not occur, click the button below:<br/><br/><a href='/login'>GO TO LOGIN</a><br/><br/>Thank you for choosing CallWebCom!",
        "activation_ko": "Error during activation phase or user already active."
      },
      "register": {
        "registrazione_account": "Account Registration",
        "enter_username": "Enter Username",
        "enter_password": "Enter Password",
        "enter_email": "Enter Email",
        "username_required": "Username Required",
        "password_required": "Password Required",
        "email_required": "Email Required",
        "email_invalid": "Invalid Email",
        "crea_account": "Create Account",
        "login": "Login",
        "register_failed": "Register Failed.",
        "email_exists": "Email already used.",
        "username_exists": "Username already used.",
        "password_do_not_match": "The passwords do not match.",
        "registrazione_fallita": "Registration failed. Please try again with a different username or a more complex password.",
        "registrazione_ok": "Registration successful! You will be redirected to the login page in a few seconds.",
        "password_format": "The password must be at least 8 characters long and must contain at least one uppercase letter, at least one lowercase letter and at least a number."
      },
      "forgot_password": {
        "recupero_password": "Password Recovery",
        "login": "Login",
        "recupera_password": "Recover Password",
        "recupero_fallito": "Recover Failed.",
        "recupero_ok": "We have send to yout email the new password.",
        "inserire_username": "Enter Username",
        "inserire_email": "Enter Email",
        "username_richiesto": "Username Required",
        "email_required": "Email Required",
        "email_invalid": "Invalid Email",
      },
      "home": {
        "welcome!": "Welcome!",
        "remaining_credit": "Your remaining credit is:",
        "credit_recharge": "Recharge Credit",
        "credit_recharge_description": "In case your credit is running low, or has run out, access the section to recharge it.",
        "show_calls": "View Calls",
        "show_calls_description": "Access the list of received and made calls, from all numbers associated with your account.",
        "transactions": "Transactions",
        "transactions_description": "Check all executed transactions. You can also enable automatic monthly recharge."
      },
      "calls": {
        "lista_chiamate": "Call List",
        "CallId": "Caller",
        "Charge": "Cost",
        "Destination": "Recipient",
        "Duration": "Duration",
        "StartTime": "Start Time"
      },
      "credit": {
        "credito_attuale": "Your current credit is",
        "opzioni_lato": "Use one of the options on the side to increase your credit.",
        "ricarica_singola": "Single Recharge",
        "seleziona_importo": "Select Amount:",
        "importo": "Amount",
        "ricarica": "Recharge",
        "ricarica_ricorrente": "Enable Recharge",
        "ricarica_ricorrente_mensile": "Monthly Recurring Recharge",
        "nessuna_ricarica_ricorrente": "No recurring recharge active.",
        "sottoscrizione_attiva": "You currently have an active recurring subscription of",
        "creata_il": "created on",
        "annulla_ricarica_ricorrente": "Cancel Recurring Recharge",
        "lista_pagamenti": "Payment List",
        "data_creazione": "Creation Date",
        "id_transazione": "Transaction ID",
        "descrizione": "Description",
        "importo_euro": "Amount (€)",
        "stato": "Status",
        "ricarica_eseguita": "Recharge executed.",
        "ricarica_in_errore": "Error on recharge."
      },
      "software": {
        "pc_client_descrizione": "Save the installer on your computer! After downloading, double click on the installation file.",
        "android_client_descrizione": "Just click on the box and install the YourDialer app on your Android mobile phone!",
        "ios_descrizione": "Just click on the box and install the YourDialer app on your iPhone/iPad/iPod Apple!"
      },
      "sip": {
        "text": "If you encounter issues with registering your SIP device, you can also replace the @ character with the * character. That is, if your username is user@provider, you can also use user*provider in the SIP settings. This may resolve registration issues for some SIP devices and SIP softphones."
      }

    }
  };

  function getTranslation(page, key) {
    if (translations[language] && translations[language][page][key]) {
      return translations[language][page][key];
    } else {
      return key;
    }
  }

  return { getTranslation };
}

export default useTranslations;
